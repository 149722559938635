import { z } from 'zod'
import typeguard from '../typeguard'

import { imagesSchema, imageSrcsSchema } from './images'
import { discounTypeSchema } from '../discounTypes'


const optionSchema = z.object({
  id: z.number(),
  name: z.string(),
  images: imagesSchema.optional(),
})
export type option = z.infer<typeof optionSchema>

const optionsSchema = z.array(optionSchema)
export type options = z.infer<typeof optionsSchema>


const attributeSchema = z.object({
  id: z.number(),
  name: z.string(),
  options: optionsSchema,
})
export type attribute = z.infer<typeof attributeSchema>

const attributesSchema = z.array(attributeSchema)
export type attributes = z.infer<typeof attributesSchema>

export type discounType = z.infer<typeof discounTypeSchema>

const discountSchema = z.object({
  id: z.number(),
  metaTitle: z.string(),
  type: discounTypeSchema,
  dateEnd: z.string().optional(),
  price: z.number(),
  shops: z.array(z.number()).optional(),
})
export type discount = z.infer<typeof discountSchema>

export const discountPriceSchema = z.object({
  regularPrice: z.number(),
  discountPrice: z.number(),
  title: z.string(),
  type: discounTypeSchema,
  dateEnd: z.string().optional(),
})
export type discountPrice = z.infer<typeof discountPriceSchema>


const combinationSchema = z.object({
  key: z.string(),
  attributes: z.array(z.string()),
  options: z.array(z.number()),
  ean: z.string().optional(),
  sku: z.string().optional(),
  price: z.number(),
  deliverytime: z.number(),
  images: imagesSchema.optional(),
  discount: discountSchema.optional(),
  stock: z.number().optional(),
  cultivable: z.boolean().optional(),
  hash: z.string(),
})
export type combination = z.infer<typeof combinationSchema>

const combinationsSchema = z.array(combinationSchema)
export type combinations = z.infer<typeof combinationsSchema>


const productSchema = z.object({
  id: z.number(),
  name: z.string(),
  path: z.string(),
  metaTitle: z.string(),
  brand: z.number(),
  brandTitel: z.string(),
  brandMetaTitel: z.string(),
  brandDescription: z.string(),
  manufacturerAddress: z.string().optional(),

  description: z.string(),   
  descriptionDetails: z.string(),
  descriptionShort: z.string(),
  images: imagesSchema,
  shippingType: z.number(),
  attributes: attributesSchema.optional(),
  combinations: combinationsSchema,
  onlyStock: z.literal(true).optional(),
})
export type tProduct = z.infer<typeof productSchema>
export const isProduct = typeguard<tProduct>(productSchema)


const roductPreviewSchema = z.object({
  id: z.number(),
  key: z.string(),
  name: z.string(),
  price: z.number(),
  ean: z.string().optional(),
  sku: z.string().optional(),
  deliverytime: z.number(),
  stock: z.number().optional(),
  onlyStock: z.literal(true).optional(),
  path: z.string(),
  metaTitle: z.string(),
  brand: z.number(),
  brandTitel: z.string(),
  brandMetaTitel: z.string(),
  images: imageSrcsSchema,
  discount: discountSchema.optional(),
  shippingType: z.number(),
  options: z.array(z.number()).optional(),
  attributes: z.array(z.string()),
})
export type tProductPreview = z.infer<typeof roductPreviewSchema>
export const isProductPreview = typeguard<tProductPreview>(roductPreviewSchema)


export const productsPreviewSchema = z.array(roductPreviewSchema)
export type tProductsPreview = z.infer<typeof productsPreviewSchema>
export const isProductsPreview = typeguard<tProductsPreview>(productsPreviewSchema)
